<template>
  <div class="testExam">
      <el-table ref="multipleTable"  :data="tableData" stripe style="width: 100%" >
        <el-table-column  width="100" prop="id" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="title" label="题目" align="center">
        </el-table-column>
        <el-table-column prop="answer" label="用户答案" align="center">
        </el-table-column>
        <el-table-column prop="right_answer" label="正确选项" align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="考试时间" align="center">
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import {ehdList} from '@/api/exam.js'
export default {
    data() {
        return {
            tableData:[],
            trainer_id:null,
            exam_id:null
        }
    },
    created () {
        this.trainer_id = this.$route.query.trainer_id
        this.exam_id = this.$route.query.exam_id
        this.getList()
    },
    methods: {
        async getList() {
            const obj = {
                trainer_id:this.trainer_id,
                exam_id:this.exam_id
            }
            const res = await ehdList(obj)
            if(res.data.code == 1) {
                this.tableData = res.data.result
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .testExam{
        padding-top: 20px;
    }
</style>